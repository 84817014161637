export const networks = new Map([
  ["aleph-zero", "Aleph Zero"],
  ["arbitrum-one", "Arbitrum"],
  ["avalanche", "Avalanche C-Chain"],
  ["binance-smart-chain", "BNB Smart Chain"],
  ["binancecoin", "BNB Beacon Chain"],
  ["bitcoin", "Bitcoin"],
  ["bitcoin-cash", "Bitcoin Cash"],
  ["cardano", "Cardano"],
  ["chia", "Chia"],
  ["cosmos", "Cosmos"],
  ["cronos", "Cronos"],
  ["dash", "Dash"],
  ["dogecoin", "Dogecoin"],
  ["ethereum", "Ethereum"],
  ["ethereum-classic", "Ethereum Classic"],
  ["ethereum-pow-iou", "EthereumPoW"],
  ["ethereumfair", "EthereumFair"],
  ["xdai", "Gnosis Chain"],
  ["fantom", "Fantom"],
  ["kaspa", "Kaspa"],
  ["kava", "Kava EVM"],
  ["kusama", "Kusama"],
  ["litecoin", "Litecoin"],
  ["octaspace", "OctaSpace"],
  ["optimistic-ethereum", "Optimism"],
  ["polkadot", "Polkadot"],
  ["polygon-pos", "Polygon POS"],
  ["ravencoin", "Ravencoin"],
  ["rootstock", "Rootstock RSK"],
  ["solana", "Solana"],
  ["stellar", "Stellar"],
  ["terra", "Terra Classic"],
  ["terra-2", "Terra"],
  ["tezos", "Tezos"],
  ["the-open-network", "Ton"],
  ["tron", "Tron"],
  ["xrp", "XRP Ledger"],
  ["decimal", "Decimal"],
  ["near-protocol", "Near"],
  ["xdc-network", "XDC Network"],
  ["vechain", "VeChain"],
  ["aptos", "Aptos"],
  ["algorand", "Algorand"],
  ["shibarium", "Shibarium"],
  ["hedera-hashgraph", "Hedera"],
  ["pulsechain", "Pulsechain"],
  ["aurora", "Aurora"],
  ["areon-network", "Areon"],
  ["base", "Base"],
  ["flare-networks", "Flare"],
  ["moonbeam", "Moonbeam"],
  ["moonriver", "Moonriver"],
  ["taraxa", "Taraxa"],
  ["zksync", "zkSync"],
  ["playa3ull-games", "Playa3ull"],
  ["polygon-zkevm", "zkEVM"],
  ["radiant", "Radiant"],
]);

export const sortedNetworks = Array.from(networks.entries()).sort(
  ([, a], [, b]) => a.localeCompare(b),
);
